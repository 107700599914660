import { createApp } from 'vue'
import App from './App.vue'
import './js/registerServiceWorker'
import Emitter from 'tiny-emitter';
import store from './store';
import * as VueRouter from 'vue-router';
import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";

// Font Awesome
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCircleCheck, faCircleXmark, faChevronRight, faChevronLeft, faFile, faUpload, faBars, faAngleDown, faAngleUp, faCircleNotch, faTrashCan, faTriangleExclamation, faSquareCheck, faXmark, faPlus, faFileImport, faFileInvoiceDollar, faFloppyDisk, faLink } from '@fortawesome/free-solid-svg-icons'
library.add(faCircleCheck, faCircleXmark, faChevronRight, faChevronLeft, faFile, faUpload, faBars, faAngleDown, faAngleUp, faCircleNotch, faTrashCan, faTriangleExclamation, faSquareCheck, faXmark, faPlus, faFileImport, faFileInvoiceDollar, faFloppyDisk, faLink)

// Tailwind CSS
import './css/tailwind.css'

// Create App
const app = createApp(App)

// Set up router
let currentYear = new Date().getFullYear()
let currentMonth = new Date().getMonth()
const routes = [
    { path: '/:year/:month/:ssp', component: App },

    // not found redirects
    { path: '/', redirect: "/" + currentYear + "/" + currentMonth + "/AdDefend" },
    { path: '/:year', redirect: "/" + currentYear + "/" + currentMonth + "/AdDefend" },
    { path: '/:year/:month', redirect: "/" + currentYear + "/" + currentMonth + "/AdDefend" },
]
const router = VueRouter.createRouter({
    history: VueRouter.createWebHistory(),
    routes,
})


// Figure out environment
let environment = "development"
if (window.location.href.includes("hmgbs.com")) {
    environment = "production"
}

// Sentry
if (environment === "production") {
    Sentry.init({
        app,
        dsn: "https://051c5d47aa804e1ca28f8cce2c119ba9@o456241.ingest.sentry.io/4504520348008448",
        environment: environment,
        integrations: [
            new BrowserTracing({
                tracePropagationTargets: ["pam.hmgbs.com", /^\//],
            })
        ],
        tracesSampleRate: 1.0,
        logErrors: true,
        trackComponents: true,
        hooks: ["mount"],
        autoSessionTracking: true
    });
}

// Configure App
app.config.globalProperties.$msalInstance = {};
app.config.globalProperties.$emitter = new Emitter();
app.component('font-awesome-icon', FontAwesomeIcon)
    .use(store)
    .use(router)

router.isReady().then(() => {
    app.mount('#app')
})
